.App {
  -webkit-touch-callout: none; /* Safari */
  -webkit-user-select: none; /* Chrome */     
     -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; 
}
.moveUp {
  transition-timing-function: ease;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes loader {
  0% {
    rotate: 0deg;
    transform: 'translateX(0%)';
  }
  25% {
    /*rotate: y 90deg;*/
    scale: 0.5;
  }
  50% {
     scale: 1.5;
  }
  75% {
   /* rotate: x 270deg;*/
    border-radius: 50%;
    translateX: 100%;
  }
  100% {
    scale: 1;
  }
}

@supports not (rotate: 0deg) {
  @keyframes loader {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: scale(0.5) rotateY(90deg);
    }
    50% {
      transfrom: rotate(180deg);
    }
    75% {
      transform: rotateX(270deg);
      border-radius: 50%;
    }
    100% {
      transform: rotate(360deg);
    }
  }
}



body {
  overscroll-behavior: contain;
}

.text-stroke-3 {
  text-shadow: -2px -2px 0 black, 1px -2px 0 black, -2px 1px 0 black, 1px 1px 0 black;
}

.header {
  background-color: #f1f1f1;
  padding: 20px;
}

.header h1 {
  text-align: center;
  margin-bottom: 0;
  font-size: 20px;
}

.header p {
  text-align: center;
}

.header .logo {
  text-align: center;
}

.header .logo img {
  cursor: pointer;
  width: 100px;
}

.menu {
  background-color: #000;
  text-align: center;
  padding: 15px 0;
}

.menu .menuItem {
  display: inline-block;
  margin: 0 30px;
}

.menu .menuItem a {
  color: #fff;
  text-decoration: none;
}

.page {
  padding: 30px;
}

.page h2 {
  text-align: center;
}

.page p {
  text-align: center;
}

.inputs .input {
  text-align: center;
}

.inputs .input input {
  height: 30px;
  width: 300px;
  margin: 5px 0;
}

.inputs .button {
  text-align: center;
}

.inputs .button button {
  background-color: #555;
  border: none;
  padding: 15px 30px;
  color: #fff;
  width: 308px;
  margin: 10px 0;
}

.error {
  background-color: red;
  width: 280px;
  margin: 5px 0;
  margin: 0 auto;
  padding: 15px;
  color: #fff;
}
